import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { useSearchParams, useLocation } from "react-router-dom";
import "./../App.css";
import logo from "./../logo.png";
import qs from "qs";
import "bootstrap/dist/css/bootstrap.min.css";

const Payment = () => {
  const BACKEND_URL = "https://staging.api.snp.t05pay.sg";

  const loc = useLocation();
  const search = loc.search;
  const resultSearchParams = qs.parse(search, { delimiter: /[?;]/ });
  console.log(`resultSearchParams: ${JSON.stringify(resultSearchParams)}`);

  const [amount, setAmount] = useState("");

  const handleChange = (e) => {
    let value;
    if (e.target.value != 0) {
      value = Math.trunc(100 * e.target.value) / 100;
    } else {
      value = e.target.value;
    }

    setAmount(value);
  };

  return (
    <div className="App">
      <header className="App-header">
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
        ></link>
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-6 offset-sm-3 d-flex justify-content-center logogroup">
              <img className="logo-component" src={logo} width="60px"></img>
              <p className="logoname logo-component">T05 PAY</p>
            </div>
          </div>
        </div>
      </header>
      <body className="bg-payment">
        <div className="container">
          <div className="row m-0">
            <div className="col-12 col-sm-6 offset-sm-3">
              <div className="row">
                <div className="col-12 mb-4">
                  <div className="row box-right-payment">
                    <div className="ps-0 ">
                      <p className="ps-0 fw-bold h6 mb-2">Pay to: </p>

                      <p className="ps-3">
                        <i className="fas fa-store secondary-color"></i>
                        <span className="h6 ps-1">
                          {" "}
                          {/* {paramsObj.get("salesChannelName")}{" "} */}
                          {resultSearchParams.salesChannelName}
                        </span>
                      </p>

                      <form action={`${BACKEND_URL}/payment/`} method="get">
                        <div className="d-flex pb-2 pt-3">
                          <p className="fw-bold h6"> Amount ($)</p>
                          {/* 'return event.charCode >= 48 && event.charCode <= 57 || event.key === "Backspace"' */}
                        </div>
                        <div className="row">
                          <div className="col-12 mb-2">
                            <input
                              className="form-control"
                              id="amount"
                              name="amount"
                              type="number"
                              step="0.01"
                              value={amount}
                              required
                              onChange={handleChange}
                              placeholder="0.00"
                            ></input>
                          </div>
                        </div>
                        <input
                          type="hidden"
                          id="secret"
                          name="secret"
                          // value={paramsObj.get("secret")}
                          value={resultSearchParams.secret}
                          required
                        />
                        <input
                          className="btn btn-primary d-block h8 col-12"
                          type="submit"
                          value="PAY"
                        />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </div>
  );
};

export default Payment;
